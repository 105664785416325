import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PageLayout from '@components/layout/PageLayout';
import PageHero from '@components/layout/PageHero';
import * as styles from '@css/modules/about.module.scss';
import { useMPEvent } from '@util/mixpanel';

export default function About({ data, pageContext }: any) {
  const {
    node_locale: nodeLocale,
    pageTitle,
    header,
    subTitle,
    subTitleLineTwo,
    pageContent,
    supportersSubtitle,
    supportersSubtitleUrl,
    supporterImages,
  } = data.contentfulAboutPage;
  const { trackPageLoad } = useMPEvent();
  useEffect(() => {
    trackPageLoad({
      language: nodeLocale.toLowerCase(),
      url: `/${nodeLocale}/about`,
      url_name: 'about',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    // @ts-expect-error
    <PageLayout
      node_locale={nodeLocale}
      page={pageContext.pageName}
      pageTitle={pageTitle}
    >
      <PageHero data={{ header, subTitle, subTitleLineTwo }} />
      <div className={styles.background}>
        <Container className="d-flex justify-content-start">
          <div className={styles.pageContent}>
            {renderRichText(pageContent, {})}
            <Row
              xs={2}
              sm={2}
              md={3}
              lg={5}
              className={styles.supporterImageWrapper}
            >
              {supporterImages.map((image: any, i: number) => (
                <Col
                  className="p-4 d-flex align-items-center justify-content-center"
                  key={i}
                >
                  <img
                    className={styles.supporterImage}
                    alt={image.title}
                    key={i}
                    src={image.fixed?.src}
                  />
                </Col>
              ))}
            </Row>
            <div className={styles.supportersSubtitle}>
              {supportersSubtitle}
              <a
                href={supportersSubtitleUrl.url}
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {supportersSubtitleUrl.copy}
              </a>
            </div>
          </div>
        </Container>
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query($node_locale: String!) {
    contentfulAboutPage(node_locale: { eq: $node_locale }) {
      node_locale
      pageTitle
      header
      subTitle
      subTitleLineTwo
      pageContent {
        raw
      }
      supportersSubtitle
      supportersSubtitleUrl {
        copy
        url
      }
      supporterImages {
        title
        fixed {
          src
        }
      }
    }
  }
`;
